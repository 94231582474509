import React from "react";

const dataprotection = () => (
  <>
<h1>Privacy policy</h1>

<h3>Basics</h3>
<p>This data protection declaration is intended to inform the users of this website about the type, scope and purpose of the collection and use of personal data by the website operator (see legal notice).
The website operator takes your data protection very seriously and treats your personal data confidentially and in accordance with legal regulations. Since changes to this data protection declaration may be made due to new technologies and the constant further development of this website, we recommend that you read the data protection declaration again at regular intervals.
Definitions of the terms used (e.g. “personal data” or “processing”) can be found in Art. 4 GDPR.</p>


<h3>Access data</h3>
<p>We, the website operator or page provider, collect data on access to the website on the basis of our legitimate interest (see Art. 6 Para. 1 lit. f. GDPR) and save these as “server log files” on the website&apos;s server. The following data is logged in this way:</p>

<ul>
<li>Visited website</li>
<li>Time at the time of access</li>
<li>Amount of data sent in bytes</li>
<li>Source / reference from which you came to the page</li>
<li>Browser used</li>
<li>Operating system used</li>
<li>IP address used</li>
</ul>

<p>The server log files are stored for a maximum of 7 days and then deleted. The data is stored for security reasons, e.g. B. to clarify cases of abuse. If data have to be kept for reasons of evidence, they are excluded from deletion until the incident has been finally resolved.</p>

<h3>Cookies</h3>
<p>This website does not use cookies.</p> 

<h3>Third party tools and services</h3>
<p>This website does not use any tools or services from third parties.</p>

<h3>User rights</h3>
<p>As a user, you have the right to request free information about which personal data has been stored about you. You also have the right to correct incorrect data and to restrict the processing or deletion of your personal data. If applicable, you can also exercise your right to data portability. If you assume that your data has been processed unlawfully, you can submit a complaint to the responsible supervisory authority.</p>

<h3>Deletion of data</h3>
<p>If your request does not conflict with a legal obligation to store data (e.g. data retention), you have the right to have your data deleted. Data stored by us will be deleted if they are no longer required for their intended purpose and there are no statutory retention periods. If deletion cannot be carried out because the data is required for permissible legal purposes, data processing will be restricted. In this case, the data will be blocked and not processed for other purposes.</p>

<h3>Right to object</h3>
<p>Users of this website can make use of their right of objection and object to the processing of their personal data at any time.
If you would like a correction, blocking, deletion or information about the personal data stored about you or if you have questions regarding the collection, processing or use of your personal data or if you would like to revoke your consent, please contact the following e-mail address : birgit.bathke@gmail.com</p>
  </>
);

export default dataprotection;
